import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { RiskImpactFormDTO, RiskImpactListDTO } from '../dto/risk-impact.dto';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RiskImpactConversion
  implements MainConversionInterface<RiskImpactListDTO, RiskImpactFormDTO, any>
{
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): RiskImpactListDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => {
      return {
        id: item.id,
        title: item[`title_${this.lang}`],
        score: item.score,
        description: item[`description_${this.lang}`],
        status: item.status,
      };
    });
  }

  formGroupToForm(formGroup: FormGroup): RiskImpactFormDTO {
    return {
      id: parseInt(formGroup.get('Id')?.value),
      title_en: formGroup.get('TitleEn')?.value,
      title_ar: formGroup.get('TitleAr')?.value,
      description_en: formGroup.get('DescriptionEn')?.value,
      description_ar: formGroup.get('DescriptionAr')?.value,
      score: parseInt(formGroup.get('Score')?.value),
      status: formGroup.get('Status')?.value,
    };
  }

  resToForm(response: any) {
    return {
      Id: response.id,
      TitleEn: response.title_en,
      TitleAr: response.title_ar,
      DescriptionEn: response.description_en,
      DescriptionAr: response.description_ar,
      Score: response.score,
      Status: response.status,
    };
  }

  resToDetails(response: any): RiskImpactListDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      title: response[`title_${this.lang}`],
      description: response[`description_${this.lang}`],
      score: response.score,
      status: response.status,
    };
  }
}
