import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import {
  RiskRatingDetailsDTO,
  RiskRatingFormDTO,
  RiskRatingListDTO,
} from '../dto/risk-rating.dto';

@Injectable({
  providedIn: 'root',
})
export class RiskRatingConversion
  implements
    MainConversionInterface<
      RiskRatingListDTO,
      RiskRatingFormDTO,
      RiskRatingDetailsDTO
    >
{
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): RiskRatingListDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => {
      return {
        id: item.id,
        title: item[`title_${this.lang}`],
        type: item.type,
        color_code: item.color_code,
        status: item.status,
      };
    });
  }

  formGroupToForm(formGroup: FormGroup): RiskRatingFormDTO {
    return {
      id: parseInt(formGroup.get('Id')?.value),
      title_en: formGroup.get('TitleEn')?.value,
      title_ar: formGroup.get('TitleAr')?.value,
      type: formGroup.get('Type')?.value,
      color_code: formGroup.get('ColorCode')?.value,
      status: formGroup.get('Status')?.value,
    };
  }

  resToForm(response: any): any {
    return {
      Id: response.id,
      TitleEn: response.title_en,
      TitleAr: response.title_ar,
      Type: response.type,
      ColorCode: response.color_code,
      Status: response.status,
    };
  }

  resToDetails(response: any): RiskRatingDetailsDTO {
    return {
      id: response.id,
      title: response[`title_${this.lang}`],
      status: response.status,
      type: response.type,
      color_code: response.color_code,
    };
  }

  returnDetailsOrNull(response: any): RiskRatingDetailsDTO | null {
    if (!response) {
      return null;
    }

    return this.resToDetails(response);
  }
}
